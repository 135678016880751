import React, { useMemo } from 'react'

// https://stackoverflow.com/questions/69624210/how-can-i-use-usetheme-in-material-ui-5
import { ThemeProvider, useThemeUI } from 'theme-ui'
import {
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialThemeProvider,
  useTheme as materialUseTheme,
  THEME_ID
} from '@mui/material/styles'
import { zhTW } from '@mui/material/locale'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { ImageZoom as Img } from '@qiyo_components/ImageZoom'

// References
// https://mui.com/material-ui/react-accordion/
// https://mui.com/material-ui/react-tabs/
// https://mui.com/material-ui/customization/default-theme/
// https://react-bootstrap.github.io/docs/components/tabs/

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0.05 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function TabImages({ idSuffix, title, images, imageTitles }) {
  // https://www.geeksforgeeks.org/react-mui-localization/
  const [locale, setLocale] = React.useState(zhTW)
  const materialTheme = materialCreateTheme(
    {
      palette: {
        mode: 'dark'
      },
      components: {
        // Name of the component
        // MuiButtonBase: {
        //   defaultProps: {
        //     // The props to change the default for.
        //     disableRipple: true // No more ripple, on the whole application 💣!
        //   }
        // },
        // MuiTableHead: {
        //   defaultProps: {},
        //   styleOverrides: {}
        // },
        // MuiTablePagination: {
        //   defaultProps: {},
        //   styleOverrides: {
        //     selectLabel: { height: '100%' }
        //   }
        // }
      }
    },
    zhTW
  )
  const themeWithLocale = React.useMemo(
    () => materialTheme,
    [materialTheme, locale]
  )

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Box sx={{ width: '100%', overflow: 'auto' }}></Box>
      <div>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ width: '100%', p: 0, m: 0 }}>
            <Box sx={{ width: '100%', p: 0, m: 0 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                >
                  <Tab label={imageTitles[0]} {...a11yProps(0)} />
                  <Tab label={imageTitles[1]} {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Img src={images[0]} width='100%' alt={imageTitles[0]} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Img src={images[1]} width='100%' alt={imageTitles[1]} />
              </CustomTabPanel>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </ThemeProvider>
  )
}