import * as React from 'react';
import { navigate } from 'gatsby';
import TabImages from '@qiyo_components/TabImages.tsx';
import { ImageZoom } from '@qiyo_components/ImageZoom';
import Divider from '@components/Divider';
export default {
  React,
  navigate,
  TabImages,
  ImageZoom,
  Divider
};