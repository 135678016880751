import { useRef, ComponentProps, RefCallback } from 'react'
import mediumZoom, { Zoom, ZoomOptions } from 'medium-zoom'
import Image from 'react-bootstrap/Image'

type ImageZoomProps = ComponentProps<'img'> & {
  options?: ZoomOptions
}

export function ImageZoom({ options, ...props }: ImageZoomProps) {
  const zoomRef = useRef<Zoom | null>(null)

  function getZoom() {
    if (zoomRef.current === null) {
      zoomRef.current = mediumZoom({...options, background: '#BADA55', margin: 0,})
    }

    return zoomRef.current
  }

  const attachZoom: RefCallback<HTMLImageElement> = node => {
    const zoom = getZoom()

    if (node) {
      zoom.attach(node)
    } else {
      zoom.detach()
    }
  }

  return <Image {...props} ref={attachZoom} loading="lazy" />
}

// https://github.com/francoischalifour/medium-zoom?tab=readme-ov-file
// https://gatsby-per-page-manifest.netlify.app/packages/gatsby-remark-images-zoom/?=image
// 
// ![Alt text for the image](/path/to/your/image.jpg)
// <figure> <img src="/path/to/your/image.jpg" alt="Alt text for the image" width="500" height="300" /> <figcaption> Caption for your image.</figcaption> </figure>