import * as React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import fileDownload from 'js-file-download'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Stack, Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
// import Accordion from 'react-bootstrap/Accordion'
// import Offcanvas from 'react-bootstrap/Offcanvas'
import ProgressBar from 'react-bootstrap/ProgressBar'
// import Col from 'react-bootstrap/Col'
// import Container from 'react-bootstrap/Container'
// import Image from 'react-bootstrap/Image'
// import Row from 'react-bootstrap/Row'
import { navigate, Link } from 'gatsby'
import { ImageZoom } from '@qiyo_components/BootstrapImageZoom'

// https://www.gatsbyjs.com/docs/adding-search-with-js-search/#js-search-with-a-small-to-medium-dataset

// https://axios-http.com/docs/handling_errors
const ReportDownloader = ({ code, state }) => {
  // console.log('code=', code)

  // if (code === undefined) {
  //   code = 'test2'
  // }

  const [isLoading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [imageDesp, setImageDesp] = useState(null)
  const [pageUrl, setPageUrl] = useState(null)
  const [rptUrl, setRptUrl] = useState(null)
  const [rptFilename, setRptFilename] = useState(null)
  const [countOfProgess, setCountOfProgess] = useState(0)
  const [progressVisible, setProgressVisible] = useState(false)
  const [invalidReport, setInvalidReport] = useState(false)

  useEffect(() => {
    const metaJsonUrl = `/report/meta/${code}.json`

    // if (!state) {
    //   console.log('Downloader: no state')
    // }
    // else
    // {
    //   console.log('Downloader: state=', state)
    // }

    setLoading(true)

    const handleNoReport = () => {
      // console.log('handleNoReport')
      setInvalidReport(true)
    }

    // console.log('get meta json: ', metaJsonUrl)
    // /report/meta/test2.json
    axios({
      url: metaJsonUrl,
      method: 'GET',
      responseType: 'blob' // Important
    })
      .then(response => {
        // console.log('response=', response)
        // console.log('response.data=', response.data)
        let reader = new FileReader()
        reader.onload = event => {
          let content = JSON.parse(reader.result)
          //   console.log('content=', content)
          if ('code' in content) {
            // console.log('code=', content['code'])
            // console.log('desp=', content['desp'])
            setImageDesp(content['desp'])
            // console.log('thumb=', content['thumb'])
            setImageSrc(content['topImage'])
            // console.log('rpt=', content['rpt'])
            // console.log('pageUrl=', content['pageUrl'])
            setPageUrl(content['pageUrl'])
            setRptUrl(content['rpt'])
            setRptFilename(content['rptFilename'])
            setVisible(true)
          } else {
            handleNoReport()
          }
        }
        reader.readAsText(response.data)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('error response', error.response.data)
          console.log('error response', error.response.status)
          console.log('error response', error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log('error request', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error: ', error.message)
        }
        handleNoReport()
      })
      .finally(function () {
        // always executed
        setLoading(false)
      })
  }, [code])

  const handleDownload = () => {
    // console.log('download')

    const handleDownloadFail = () => {
      setCountOfProgess(-1)
      // console.log('handleDownloadFail, countOfProgess=', countOfProgess)
    }

    axios({
      url: rptUrl,
      method: 'GET',
      responseType: 'blob', // Important
      onDownloadProgress(progressEvent) {
        let progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )

        setCountOfProgess(100 + progress * 0.5)
      }
    })
      .then(response => {
        // console.log('rpt response=', response)
        fileDownload(response.data, rptFilename)
        setCountOfProgess(150)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('error response', error.response.data)
          console.log('error response', error.response.status)
          console.log('error response', error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log('error request', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error: ', error.message)
        }
        handleDownloadFail()
      })
      .finally(function () {
        // always executed
        setLoading(false)
      })
  }

  const handleClick = () => {
    setLoading(true)
    setProgressVisible(true)
    setCountOfProgess(0)

    const timer = setInterval(() => {
      setCountOfProgess(oldProgress => {
        if (100 === oldProgress) {
          clearInterval(timer)
          handleDownload()
          return oldProgress
        }
        return Math.min(oldProgress + Math.random() * 10, 100)
      })
    }, 499)
  }

  //   《{imageDesp}》

  return (
    <>
      <div
        style={
          invalidReport
            ? { visibility: 'visible' }
            : { visibility: 'hidden', display: 'none' }
        }
      >
        <Alert show={invalidReport} variant='success'>
          <Alert.Heading>找不到您所要下載的報表</Alert.Heading>
          <p>請回首頁重新下載</p>
          <hr />
          <div className='d-flex justify-content-end'>
            <Button
              onClick={() => {
                navigate('/')
              }}
              variant='success'
            >
              回首頁
            </Button>
          </div>
        </Alert>
      </div>

      <div
        style={
          visible
            ? { visibility: 'visible' }
            : { visibility: 'hidden', display: 'none' }
        }
      >
        <Stack
          style={
            visible
              ? { visibility: 'visible', marginBottom: 20 }
              : { visibility: 'hidden', display: 'none' }
          }
        >
          <ImageZoom src={imageSrc} rounded alt={imageDesp} />
        </Stack>
        <Stack
          style={
            visible
              ? { visibility: 'visible', marginBottom: 20 }
              : { visibility: 'hidden', display: 'none' }
          }
        >
          {false && <h4>報表：<div style={{color:'gold'}}>〈 {imageDesp} 〉</div></h4>}
          <h4>報表：<em style={{color:'gold'}}>〈 {imageDesp} 〉</em></h4>
          <h4
            style={
              !pageUrl
                ? { visibility: 'hidden', display: 'none' }
                : { visibility: 'visible' }
            }
          >
            <Link to={pageUrl}>懶人包</Link>
          </h4>
        </Stack>
        <Stack
          style={
            progressVisible
              ? { visibility: 'visible' }
              : { visibility: 'hidden', display: 'none' }
          }
        >
          <div style={{ display: 'block', width: '100%', padding: 30 }}>
            <h4>正在下載報表：〈 {imageDesp} 〉</h4>
            目前進度:{' '}
            {countOfProgess < 0
              ? <strong style={{color:'orange'}}>下載失敗，無法取得報表檔案</strong>
              : parseInt(countOfProgess / 1.5) + ' %'}
            <ProgressBar
              now={countOfProgess}
              max={150}
              style={{ height: 30, marginTop: 10, width: '100%' }}
            />
          </div>
        </Stack>
        <Stack
          gap={2}
          className='col-md-5 mx-auto'
          style={{ marginBottom: 20 }}
        >
          <Button
            variant='primary'
            size='lg'
            disabled={isLoading}
            onClick={!isLoading ? handleClick : null}
          >
            {isLoading ? '正在下載…' : '下載 XLSX 試算表檔案'}
          </Button>
        </Stack>
      </div>
    </>
  )
}

export default ReportDownloader

// Other References
// https://www.youtube.com/watch?v=PbqmCsQsMXc&ab_channel=ColbyFayock
// https://www.youtube.com/watch?v=0AS9Gfd1j5s&ab_channel=ProgrammingWithPrem
// https://react-component-depot.netlify.app/file-downloader
// https://www.gatsbyjs.com/docs/location-data-from-props/
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
