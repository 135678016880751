import { Grid, Card, Box, Text, Button, Heading, Link, Image, Label, Input, Checkbox, Select, Textarea, Flex, Radio, Slider, Spinner, Badge, NavLink, Alert, Message } from "theme-ui";
import imgRedwood1 from "../../../../content/posts/family-pregnancy/how-to-change-your-familys-eating-habits/redwood-1.jpg";
import imgRedwood2 from "../../../../content/posts/family-pregnancy/how-to-change-your-familys-eating-habits/redwood-2.jpg";
import * as React from 'react';
export default {
  Grid,
  Card,
  Box,
  Text,
  Button,
  Heading,
  Link,
  Image,
  Label,
  Input,
  Checkbox,
  Select,
  Textarea,
  Flex,
  Radio,
  Slider,
  Spinner,
  Badge,
  NavLink,
  Alert,
  Message,
  imgRedwood1,
  imgRedwood2,
  React
};