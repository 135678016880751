import ButtonUsage from "@qiyo_components/MaterialButton.js";
import DataTable from "@qiyo_components/DataTable.tsx";
import DataTable2 from "@qiyo_components/DataTable2.tsx";
import DataTable3 from "@qiyo_components/DataTable3.tsx";
import * as React from 'react';
export default {
  ButtonUsage,
  DataTable,
  DataTable2,
  DataTable3,
  React
};