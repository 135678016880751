// import React from 'react'

const AppInfo = {
  companyChineseName: '奇遊創意網路科技有限公司',
  companyEnglishName: 'QiYo Creative Network Technology Co., Ltd.',
  siteName: '台股懶人包',
  siteUrl: 'https://www.qiyoapps.com',
  serviceName: '台灣股市交易策略智慧回測懶人包',
  legalEmail: 'service@qiyoapps.com',
  baseName: '/'
}

// export const CompanyName = () => {
//     return <>{AppInfo.companyChineseName}</>
// }

// export function CompanyName() {
//     return <>{AppInfo.companyChineseName}</>
// }

export default AppInfo

// export default {
//     AppInfo,
//     CompanyName,
// }